<template>

  <form
    v-if="data"
    class="AccountForm"
    @submit.prevent="onSubmit">
    <h2>{{ mixWB('ACCOUNT') }}</h2>

    <InputField
      formGroupName="account-form"
      :value="data.name"
      :placeholder="mixWB('ACCOUNT_NAME')"
      isRequired
      name="name"
      :disabled="!canEdit"
      @on-update="onUpdate" />

    <InputField
      formGroupName="account-form"
      :value="data.address"
      :placeholder="mixWB('ADDRESS')"
      isRequired
      name="address"
      :disabled="!canEdit"
      @on-update="onUpdate" />

    <InputField
      formGroupName="account-form"
      :value="data.postalCode"
      :placeholder="mixWB('POSTAL_CODE')"
      isRequired
      name="postalCode"
      :disabled="!canEdit"
      @on-update="onUpdate" />

    <InputField
      formGroupName="account-form"
      :value="data.city"
      :placeholder="mixWB('CITY')"
      isRequired
      name="city"
      :disabled="!canEdit"
      @on-update="onUpdate" />

    <InputField
      formGroupName="account-form"
      :value="data.contactPerson.name"
      :placeholder="mixWB('CONTACT_PERSON_NAME')"
      isRequired
      name="contactPerson.name"
      :disabled="!canEdit"
      @on-update="onUpdate" />

    <InputField
      formGroupName="account-form"
      :value="data.contactPerson.phoneNumber"
      :placeholder="mixWB('CONTACT_PERSON_PHONE')"
      isRequired
      name="contactPerson.phoneNumber"
      :disabled="!canEdit"
      @on-update="onUpdate" />

    <InputField
      formGroupName="account-form"
      :value="data.contactPerson.email"
      :placeholder="mixWB('CONTACT_PERSON_EMAIL')"
      isRequired
      name="contactPerson.email"
      :disabled="!canEdit"
      @on-update="onUpdate" />

    <InputField
      formGroupName="account-form"
      :value="data.euroFinsNumber"
      :placeholder="mixWB('EUROFINS_NUMBER')"
      isRequired
      name="euroFinsNumber"
      :disabled="!canEdit"
      @on-update="onUpdate" />

    <Button
      v-if="canEdit"
      :isLoading="isSaving"
      :text="mixWB('SAVE')"
      type="submit" />

    <Accordion
      :title="mixWB('STAT_TABLE_TITLE')"
      :bodyComponent="Stat"
      :bodyComponentProps="{
        stats: currentAccount,
      }"
      :padding="'none'"
      v-if="currentAccount.overallStats" />
  </form>
</template>

<script>
import InputField from '@/components/FormElements/InputField.vue'
import Button from '@/components/Buttons/Button.vue'
import { mapGetters } from 'vuex'
import { set } from 'lodash-es'
import EventBus from '@/EventBus'
import Accordion from '@/components/Accordion.vue'
import Stat from '@/components/Stat.vue'

export default {
  name: 'Account',
  data() {
    return {
      isSaving: false,
      data: {
        name: '',
        address: '',
        postalCode: '',
        city: '',
        contactPerson: {
          name: '',
          phoneNumber: '',
          email: '',
        },
        euroFinsNumber: '',
      },
      Stat,
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'currentAccount',
    ]),
    canEdit() {
      return this.currentUser.isSuperUser || this.currentUser.isAdmin
    },
  },
  methods: {
    onUpdate({ name, value }) {
      set(this.data, name, value)
    },
    onSubmit() {
      this.isSaving = true
      EventBus.$emit('validate-field', 'account-form')

      // Check all required fields
      if (!this.data.name
        || !this.data.address
        || !this.data.postalCode
        || !this.data.city
        || !this.data.contactPerson.name
        || !this.data.contactPerson.phoneNumber
        || !this.data.contactPerson.email
        || !this.data.euroFinsNumber) {
        this.isSaving = false

        return
      }

      this.$store.dispatch('updateAccount', { data: this.data, id: this.currentAccount.id })
        .then(() => {
          this.isSaving = false
        })
    },
  },
  components: {
    InputField,
    Button,
    Accordion,
  },
  created() {
    Object.keys(this.data).forEach((key) => {
      if (typeof this.currentAccount[key] !== 'undefined') {
        this.data[key] = typeof this.currentAccount[key] === 'object'
          ? { ...this.currentAccount[key] }
          : this.currentAccount[key].toString()
      }
    })
  },
}
</script>

<style lang="stylus" scoped>

  >>> .Accordion
    margin-top 30px
    .Inner
      overflow auto

</style>
